@keyframes slideIn {
  from {
    transform: scale(1.07, 1.07); }
  to {
    transform: scale(1, 1); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
