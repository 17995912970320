html {
  font-size: 1vh;
}

.code-page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: auto;


  > img.background {
    height: 100%;
    background-size: cover;
    max-width: none;
  }

  > img.title {
    position: absolute;
    height: 20%;
    top: 12%;
  }

  > .interactables {
    position: absolute;
    top: 45%;

    > div {
      margin: 3vh auto;
    }

    > p.version {
      margin-top: 1vh;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  button, input {
    font-size: 3rem;
  }

  button {
    font-weight: bold;
  }
}
