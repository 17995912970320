@import "./shrine/traps.scss";
.shrine-map-container {
  margin: auto;
  position: fixed;
  padding-top: 12.5vh;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.shrine-map-container>div>img.map-image {
  width: 100%;
  height: auto;
}

.shrine-map-focus {
  transform: scale(1.3) translate(5%, 0%);
  margin: auto;
  width: 90.44433827042523vh;
  height: 100vh;
  max-height: 110.5652403592182vw;
  max-width: 100vw;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: transform 1s;
}

.shrine-map-focus.grave {
  transform: scale(2.5) translate(-8%, 20%);
}

.shrine-map-focus.cave {
  transform: scale(3.5) translate(18%, 24%);
}

.shrine-map-focus.tunnel {
  transform: scale(3.0) translate(19%, 0%);
}

.shrine-map-focus.shrine {
  transform: scale(3.0) translate(-10%, -1.5%);
}

.shrine-map-focus.entrance {
  transform: scale(3.5) translate(-9%, -25%);
}

.shrine-map-sections {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  >svg.disabled {
    display: none;
  }
}

svg {
  width: 100%;
}

path {
  cursor: pointer;
}

.shrine-map-back-button {
  position: absolute;
  left: 5vw;
  height: 40%;
  cursor: pointer;
}

.shrine-map-back-button.disabled {
  display: none;
}

.shrine-map-section {
  fill: white;
  fill-opacity: 0.1;
  stroke: white;
  stroke-width: 'thicc';
  stroke-opacity: 0.6;
}
