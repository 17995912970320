.banner {
    display: grid;
    
    width: 100%;
    height: 10vh;
    
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;

    font-weight: 700;
    font-size: 1.5rem;
    background-color: #091523;
    font-weight: 700;
    color: white;
    z-index: 2;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    
    grid-area: 1 / 1 / 2 / 2;

 

    .shrine-map-back-button {
        z-index: 1;
        height: 5.25rem;
        width: 5.25rem;
        cursor: pointer;
        background: url(../../images/arrow.png);
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
        position: absolute;
        left: 1vh;
        top: 2vh;
        place-self: center center;
        grid-area: 1 / 1 / 3 / 2;

        &.disabled {
            display:none;
        }
    }
    
}

.blue-bg {
    display: grid;
    background-color: #091523;

    .banner-header {
        width: 100%;
        height: inherit;
        
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-area: 1 / 1 / 3 / 4;
        
        padding-top: 5px;

        text-transform: uppercase;
        white-space: nowrap;
        
        font-weight: 700;
        font-size: 2rem;
        
        .red {
            color: red;
        }

        @media only screen and (max-width: 600px) {
            font-size: 2.0rem;
        }


        .score-container {
            grid-area: 1 / 1 / 2 / 2;
            place-self: start center;
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 0px;
            .player-score{
                grid-area: 1 / 1 / 1 / 1;
            }
            .score-tracker {                
                justify-content: flex-start;
                grid-area: 2 / 1 / 2 / 1;
                font-size: 3rem;
            }
        }
        .timer-container {
            display: grid;
            grid-area: 1 / 2 / 2 / 2;
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 0px;

            .time {
                grid-area: 1 / 1 / 1 / 1;
            }
            .time-remaining {
                grid-area: 2 / 1 / 2 / 1;
            }
        }

        .location-container {
            grid-template-rows: repeat(2, 1fr);
            grid-row-gap: 0px;
            grid-area: 1 / 3 / 2 / 3;
            display: grid;

            .location {
                grid-area: 1 / 1 / 1 / 1;
            }
            .room-name {
                grid-area: 2 / 1 / 2 / 1;
                text-transform: capitalize;
            }
        }
        .close {        
            grid-area: 1 / 4 / 3 / 5;

            .delete {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .trap-button-container p {
            font-size: 2rem;
        }
    }
}


.section-banner-intro {    
    text-align: center;
    grid-area: 1 / 1 / 3 /4;
    align-self: center;
    >p:last-of-type {
        margin-top: 10px;
    }
}


.house {
    display: flex;

}

header{
    &.shrine {
        display: flex;
    }
}