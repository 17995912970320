.score-page {
  display: flex;
  justify-content: center;
  color: #ffffff;
  width: 100%;
  max-height: 100%;
  overflow-y: hidden;
  font-size: 1vh;
  animation: fadein 6.5s;
  -moz-animation: fadein 6.5s;
  /* Firefox */
  -webkit-animation: fadein 6.5s;


  /* Safari and Chrome */
  * {
    font-weight: bold;
  }

  .score-page-nav {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 3vh;
    position: relative;
    top: 0;
    text-align: center;
    background-color: #091523;
    width: 100vw;
    height: 10vh;
    margin-bottom: 4vh;
    h1 {
      position: absolute;
      padding-top: 2.5vh;
      left: 0;
      right: 0;
    }
  }

  .home-button {

    font-size: 3vh;
    margin: 0.4em auto;
    border-radius: 0.3em;
    border: 2px solid white;
    border-radius: 0.3em;
    background: white;
    text-align: center;
    width: 11.5em;
    margin-bottom: 3vh;

    vertical-align: top;

    cursor: pointer;
  }



  .score-page-container {
    display: flex;
    width: 100%;

    flex-direction: column;
    align-items: center;
    font-size: 3vh;
    animation: fadein 6.5s;
    -moz-animation: fadein 6.5s;
    /* Firefox */
    -webkit-animation: fadein 6.5s;

  }

  .players {
    width: 45vh;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
  }

  table {
    display: table;
    width: 7vh;
    border-spacing: 1vw;
    animation: fadein 6.5s;
    -moz-animation: fadein 6.5s;
    /* Firefox */
    -webkit-animation: fadein 6.5s;
    /* Safari and Chrome */
    thead {
      background-color: #091523;
      font-size: 2.5rem;
      th {
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: white;
        border-right: 1px solid white;
      }

      th:first-of-type {
        padding-left: 10px;
      }

      th:last-of-type {
        border-right: none;
      }
    }

    tbody {
      width: 100%;
      tr {
        height: 4vh;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
      td:first-of-type,
      td:last-of-type {
        font-size: 2.5rem;
        padding-top: 0;
      }
      td:nth-of-type(2){
        padding-top: 5px;
        font-size: 1.75rem;
      }
      .self {
        background-color: rgba(255, 0, 0, 0.2);
      }
    }
  }

  .player-ranking {
    text-align: left;
    padding-left: 20px;
  }

  .kill:first-of-type {
    background-image: url(../../images/skull_icon.png);
    background-repeat: no-repeat;
    background-size: 2.75rem;
    background-position: 35px;
  }

  .player-name {
    justify-content: left;
    padding-left: 10px;
  }

  .player-score {
    justify-content: right;
    text-align: right;
    padding-right: 25px;
  }

  .group {
    position: absolute;
    bottom: 3vh;
    text-align: center;
    width: 100%;

  }

  .score-table-container {
    justify-content: center;
    margin-bottom: 7vh;
  }


}

.win {
  background-image: url(../../images/score-page/Score_BG3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw
}

.lost {
  background-image: url(../../images/score-page/Score_BG4.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw
}

@media only screen and (max-width: 600px) {
  .win {
    background-image: url(../../images/score-page/Score_BG2.jpg);
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100vw;
  }

  .lost {
    background-image: url(../../images/score-page/Score_BG.jpg);
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100vw;
  }

  table {
    display: table;
    width: 10vh;
    border-spacing: 1vw;
  }

  .players {
    width: 40vh;
  }

}


@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {

  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {

  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
