@charset "utf-8";

@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "./components/real-time-picker.scss";
@import "./components/score-popup.scss";

$white: hsl(0, 0%, 100%);
$blue: hsl(208, 50%, 18%);
$modal-card-title-color: $white;
$primary: $blue;

#root {
    height: 100%;
    font-family: Century Gothic, "Century Gothic", CenturyGothic, Helvetica;
}

html, body {
    min-height: 100%;
    height: 100%;
    overflow-x: hidden;
}
body {
    position: relative;
}

@import "~bulma/bulma";
@import "components/all";
@import "pages/all";
@import "components/join-game-form.scss";
@import "components/social-media-container.scss";
@import "components/trap-modal.scss";
@import "components/banner-header.scss";

.quit-container {
    padding-left: 5%;
    padding-right: 5%;
}
