.social-media-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > a {
    margin: 0 2.5vh;

    > img {
      height: 8vh;
      border-radius: 1vh;
    }
  }

  @media (max-aspect-ratio: 347 / 666) {
    > a {
      margin: 0;
    }
  }
}
