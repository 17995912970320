.join-game-form {
  display: flex;
  flex-direction: column;

  .input,
  .button {
    margin: 0.4em auto;
    border-radius: 0.3em;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    width: 11.5em;
  }

  .input {
    background: rgba(0, 0, 0, 0.6);
    border: 2px white solid;
    color: white;

    &::placeholder {
      color: white;
      opacity: 0.6;
    }
  }

  .button {
    background: white;
    border: none;

    &[disabled] {
      cursor: default;
    }
  }
}
