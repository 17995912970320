.end-page {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  * {
    font-weight: bold;
  }

  > .end-container {
    margin: auto;
    background-color: black;
    text-align: center;

    height: 100%;

    > * {
      margin-left: auto;
      margin-right: auto;
    }

    > .reiko-icon {
      height: 25vh;
    }

    > .group {
      margin: 1vh auto;

      > p,
      > a,
      > button {
        margin: 0 auto;
        font-size: 5vh;
      }

      > *:last-child {
        margin-bottom: 2vh;
      }

      > p {
        color: #909090;
      }

      > button {
        border-radius: 0.2em;
        background: white;
        border: none;
        padding: 0.2em 0.4em;
        font-weight: bold;
        cursor: pointer;
      }

      > .icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > a {
          margin: 0 2.5vh;

          > img {
            height: 8vh;
          }
        }

        @media (max-aspect-ratio: 347 / 666) {
          > a {
            margin: 0;
          }
        }
      }

      > .icons.steam {
        > a > img {
          height: 14vh;
        }

        > p {
          font-size: 5vh;
          color: white;
          letter-spacing: 0.1em;
          font-weight: normal;
        }
      }
    }
  }
}
