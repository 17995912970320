.button.is-dark.is-rounded.is-medium.is-disabled {
  opacity: 0.5; }
.button.is-dark.is-rounded.is-medium.is-disabled:active,
.button.is-dark.is-rounded.is-medium.is-disabled.is-active,
.button.is-dark.is-rounded.is-medium.is-disabled:hover,
.button.is-dark.is-rounded.is-medium.is-disabled.is-hovered {
  background-color: #363636;
  cursor: auto; }

.trap-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0; }

.trap-button-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2em;
  padding: 1em;
  margin: 0.2em;
  p {
    font-size: 1.5em;
    color: whitesmoke; } }
.trap-button-container:hover {
  cursor: pointer; }

.trap-button-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center; }
.trap-button {
  max-width: 40px;
  max-height: 40px; }
.trap-label {}

.trap-icon {
  position: absolute;
  width: 5rem;
  height: 5rem;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 5rem;
    max-height: 5rem; }
  p {
    position: absolute;
    bottom: -18%;
    left: 50%;
    font-size: 1.6em;
    color: white;
    text-shadow: 3px 3px 3px #000;
    font-size: 1.25rem;
    text-transform: uppercase; } }

.position {
  position: absolute;
  // border: 0.25em solid #680031
  // background-color: #A40031
  // border-radius: 50%
  // opacity: 0.6
  width: 5em;
  height: 5em;
  transform: translateX(-50%) translateY(-50%);
 }  // color: red
.position:hover {
  cursor: pointer; }

.back-btn {
  position: absolute;
  top: 50%;
  left: 15px;
  padding: 10px;
  transform: translateY(-50%);
  color: white;
  border-color: transparent;
  background: transparent;
  font-weight: bold;
  font-size: 1.5rem; }

.close-modal {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 2rem;
  color: white;
  transform: translateX(50%) translateY(-50%) scale(1.3,1);
  background-color: black;
  line-height: 0.9;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  z-index: 10; }

.modal-title {
  text-align: center;
  font-size: 3rem; }

.arrow {
  height: 1.2em;
  margin: 0 0.2em; }

.trap-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  mix-blend-mode: screen;
  filter: blur(10px); }
