@media only screen and (max-width: 600px) {
  .realtime-picker-container {
    font-size: 2em;
    width: 100%;
  }
}

.realtime-picker-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: rgb(209, 209, 209);

  align-content: center;
  justify-content: center;
  text-align: center;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  height: 25vh;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 1) 60%);
  z-index: 1;

  font-size: 1.5rem;
  font-weight: 700;

  .realtime-picker-header {
    grid-area: 1 / 1 / 2 / 9;
    place-self: center center;
    padding-top: 3vh;

    @media only screen and (max-width: 700px) {
      position: fixed;
      bottom: 15vh;
    }

    @media only screen and (max-width: 1200px) {
      position: fixed;
      bottom: 21vh;
    }
  }

  .mana-bar-container {
    grid-area: 2 / 1 / 3 / 9;
    position: fixed;
    bottom: 10vh;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;
    @media only screen and (max-width: 700px) {
      bottom: 15vh;
    }
    @media only screen and (min-width: 1200px) {
      transform: scale(0.5);
    }

    .mana-bar-active-wrapper {
      position: absolute;
      z-index: 2;
      left: 5%;
      max-width: 90%;
      height: 20vh;
      overflow: hidden;

      .mana-bar-active {
        position: absolute;
        left: 0;
        right: 0;
        min-width: 90vw;
      }
    }

    .mana-bar {
      width: 90%;

      &.active {
        position: absolute;
        overflow: hidden;
        left: 5%;
      }
    }

    .mana-level {
      position: absolute;
      height: 20vw;
      top: -2.5vw;
      z-index: 2;
    }
  }

  .realtime-picker {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    z-index: 3;
    justify-content: space-evenly;
    grid-area: 3 / 1 / 4 / 9;
    padding-bottom: 3vh;

    .realtime-trap-button-container-1 {
      grid-area: 3 / 1 / 4 / 3;
      width: 9vh;
    }

    .realtime-trap-button-container-2 {
      grid-area: 3 / 4 / 4 / 6;
      width: 9vh;
    }

    .realtime-trap-button-container-3 {
      grid-area: 3 / 7 / 4 / 9;
      width: 9vh;
    }
  }
}

.realtime-trap-button {
  width: 5em;
  height: 5em;
  border: none;
  background-color: transparent;
  z-index: 3;

  &.active {
    cursor: pointer;
    opacity: 1;
  }

  &.inactive {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
  }

  &.fog {
    background-image: url(../../images/shrine/traps/fog_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.flicker {
    background-image: url(../../images/flicker_btn.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.whoosh {
    background-image: url(../../images/whoosh_btn.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.hands {
    background-image: url(../../images/shrine/traps/grab_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.slam {
    background-image: url(../../images/slam_btn.png);
    ;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.trap-label {
  text-transform: capitalize;
}
