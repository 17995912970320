.modal {
  color: whitesmoke;
  .modal-card {
    overflow: visible;
    padding: 0;

    &.quit-container {
      width: 35rem;
      height: 25rem;
      text-transform: uppercase;
      font-weight: 700;

      .modal-card-title.modal-title{
        color: whitesmoke !important;
      }

      .trap-modal-header-right {
          top: -6rem;
          left: 45rem;
          right: 0px;
          bottom: 0;
          position: absolute;
      }
    }

    .trap-modal-header {
      display: flex;
      text-align: center;


      .trap-modal-header-center {
        justify-content: center;
        width: 100%;
      }
    }

    .modal-card-body {
      display: grid;
      grid-template-rows: repeat(1, 1fr);
      grid-template-columns: repeat(3, 1fr);
      place-content: center center;

      &.house {
        display: flex !important;
        flex-direction: column;
        button {
          height: 100%;
          font-size: 3rem;
          font-weight: 700;
        }
      }
      .modal-button-wrapper {
        grid-area: 1 / 1 / 1 / 4;
        height: 100%;
        display: flex;

        button {
          place-self: center;
          height: 4em;
          margin-left: 1rem;
          margin-right: 1rem;
          font-weight: 700;
          background-color: whitesmoke;
          transition: all .6s ease-in-out;
          -moz-transition-: all  .6s ease-in-out;
          -o-transition: all  .6s ease-in-out;
          -webkit-transition: all  .6s ease-in-out;
          // -moz-transition-:color 1s ease-in;
          // -o-transition: color 1s ease-in;
          // -webkit-transition:color 1s ease-in;
        }

        button:focus {
          color:whitesmoke;
          background-color: darkred;
        }
      }

      .trap-button-container{
        place-self: center center;
        overflow: hidden;
        margin-bottom: 5px;
        .trap-label {
          font-size: 2rem;
          text-align: center;
        }
        .trap-button-wrapper{
          .trap-button{
            &.headless {
              background-image: url(../../images/headless.png);
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
              background-position: center;
              border: none;
              width: 5.25em;
              height: 5.25em;
              margin: 1.5em;
            }
              &.thunder {
              background-image: url(../../images/thunder.png);
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
              background-position: center;
              border: none;
              width: 5.25em;
              height: 5.25em;
              margin: 1.5em;
            }
            &.scream {
              background-image: url(../../images/scream.png);
              background-size: contain;
              background-repeat: no-repeat;
              background-color: transparent;
              background-position: center;
              border: none;
              width: 5.25em;
              height: 5.25em;
              margin: 1.5em;
            }
          }
        }
      }
    }
  }

}