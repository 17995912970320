.shrine-map-traps-container {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;

    position: absolute;
    top: -5vh;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;
}

$scale: 0.040;

.shrine-map-trap-container {
    max-width: 100%;
    max-height: 100%;
    // width: 900px;
    // height: 900px;
    // overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;

    button {
        pointer-events: all;
    }

    &.trap1 {
        transform: scale($scale) translate(17.5% / $scale, 23.75% / $scale);
        // transform: translate(437.5%, 593.75%);
    }
    &.trap2 {
        transform: scale($scale) translate(17.5% / $scale, 29.75% / $scale);
        // transform: translate(17.5%, 29.75%);
    }
    &.trap3 {
        transform: scale($scale) translate(0.2% / $scale, 28.75% / $scale);
        // transform: translate(0.2%, 28.75%);
    }
    &.trap4 {
        transform: scale($scale) translate(0.5% / $scale, 20.75% / $scale);
        // transform: translate(0.5%, 20.75%);
    }
    &.trap5 {
        transform: scale($scale) translate(4.0% / $scale, 12.75% / $scale);
        // transform: translate(4.0%, 12.75%);
    }
    &.trap6 {
        transform: scale($scale) translate(0.2% / $scale, -2.00% / $scale);
        // transform: translate(0.2%, -2.00%);
    }
    &.trap7 {
        transform: scale($scale) translate(-11.5% / $scale, 2.46% / $scale);
        // transform: translate(-11.5%, 2.46%);
    }
    &.trap8 {
        transform: scale($scale) translate(-27.60% / $scale, -0.26% / $scale);
        // transform: translate(-27.60%, -0.26%);
    }
    &.trap9 {
        transform: scale($scale) translate(-17.80% / $scale, -9% / $scale);
        // transform: translate(-17.80%, -9%);
    }
    &.trap10 {
        transform: scale($scale) translate(-12.80% / $scale, -17.8% / $scale);
        // transform: translate(-12.80%, -17.8%);
    }
    &.trap11 {
        transform: scale($scale) translate(-25.80% / $scale, -29.8% / $scale);
        // transform: translate(-25.80%, -29.8%);
    }
    &.trap12 {
        transform: scale($scale) translate(-9.87% / $scale, -24.8% / $scale);
        // transform: translate(-9.87%, -24.8%);
    }
    &.trap13 {
        transform: scale($scale) translate(-5.87% / $scale, -18.4% / $scale);
        // transform: translate(-5.87%, -18.4%);
    }
    &.trap14 {
        transform: scale($scale) translate(0.10% / $scale, -30.8% / $scale);
        // transform: translate(0.10%, -30.8%);
    }
    &.trap15 {
        transform: scale($scale) translate(3.0% / $scale, -17.4% / $scale);
        // transform: translate(3.0%, -17.4%);
    }
    &.trap16 {
        transform: scale($scale) translate(11% / $scale, -21.9% / $scale);
        // transform: translate(11%, -21.9%);
    }
    &.trap17 {
        transform: scale($scale) translate(22.5% / $scale, -18.9% / $scale);
        // transform: translate(22.5%, -18.9%);
    }
    &.trap18 {
        transform: scale($scale) translate(20.0% / $scale, -9.9% / $scale);
        // transform: translate(20.0%, -9.9%);
    }
    &.trap19 {
        transform: scale($scale) translate(18.0% / $scale, -2.5% / $scale);
        // transform: translate(18.0%, -2.5%);
    }
    &.trap20 {
        transform: scale($scale) translate(22.9% / $scale, 4.3% / $scale);
        // transform: translate(22.9%, 4.3%);
    }

}

.shrine-map-trap-image {
    width: 100%;
    height: 100%;
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 1;
    transition: opacity 1s;

    &.disabled {
        opacity: 0;
        pointer-events: none;
    }
}

.shine-map-trap-choice {
    width: 500px;
    height: 500px;

    > img {
        max-width: 100%;
        height: auto;
        transform: scale(3.5);
        position: absolute;
        margin-top: 50%;
        margin-left: 30%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
    > p {
        font-size: 100px;
        position: absolute;
        bottom: 10%;
        left: 100%;
        color: white;
        text-shadow: 2px 2px 2px #000;
        text-transform: uppercase;
    }
}

.shrine-trap-glow {
    opacity: 1;
    transition: opacity 1s;
    transform: scale(1.3) translate(-15%, -5%);
    animation-name: shrine-trap-glow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    z-index: 2;

    &.disabled {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}


@media only screen and (max-width: 375px){
    .shrine-trap-glow {
        opacity: 1;
        transition: opacity 1s;
        transform: scale(1.8) translate(-15%, -6.1%);
        animation-name: shrine-trap-glow;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        z-index: 2;

        &.disabled {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }
}

@media only screen and (min-width: 768px) {
    .shrine-trap-glow {
        opacity: 1;
        transition: opacity 1s;
        transform: scale(2.2) translate(17%, 17.8%);
        animation-name: shrine-trap-glow;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        z-index: 2;

        &.disabled {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }
}

@media only screen and (min-width: 1800px) {
    .shrine-trap-glow {
        opacity: 1;
        transition: opacity 1s;
        transform: scale(3.8) translate(29%, 30.8%);
        animation-name: shrine-trap-glow;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        z-index: 2;

        &.disabled {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }
}

@keyframes shrine-trap-glow {
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}
