.map-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; }

.map-container.house {
  display: inline-block;
  position: relative; }

.map-container.room {
  display: inline-block;
  position: relative; }

.map {
  z-index: 0;
  position: relative;
  width: auto;
  animation: fadeIn 300ms 1 ease-out; }

.map-head {
  z-index: 1; }

.map-footer {
  padding-bottom: 1.5em; }

.inactive-floor {
  background-color: rgba(0,0,0,0.6);
  border-bottom: 1.5px solid #888; }

.map-controls-container {
  position: static;
  width: 100%;
  height: 100%; }

.buttons-container {
  position: absolute;
  top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .button {
    margin: 0 20px; } }

.room-container {
  position: absolute;
  border: 2px solid white; }
.room-container:hover {
  cursor: pointer; }

.room1 {
  top: 25.5%;
  left: 4%;
  width: 40%;
  height: 22%; }
.room2_1 {
  top: 48%;
  left: 2%;
  width: 29%;
  height: 16.5%;
  border-bottom: none;
  border-right: none; }
.room2_2 {
  top: 48%;
  left: 31%;
  width: 13%;
  height: 16.5%;
  border-left: none; }
.room2_3 {
  top: 64.2%;
  left: 2%;
  width: 29%;
  height: 7.5%;
  border-top: none; }
.room3 {
  top: 12%;
  left: 47.5%;
  width: 37%;
  height: 18%; }
.room4 {
  top: 31%;
  left: 58%;
  width: 26%;
  height: 22%; }
.room5 {
  top: 55%;
  left: 64%;
  width: 34%;
  height: 29%; }
.room6_1 {
  top: 31%;
  left: 47%;
  width: 9.5%;
  height: 34%;
  border-bottom: none; }
.room6_2 {
  top: 65%;
  left: 32%;
  width: 15.6%;
  height: 19%;
  border-right: none; }
.room6_3 {
  top: 65%;
  left: 47%;
  width: 9.5%;
  height: 19%;
  border-top: none;
  border-left: none; }

.room7 {
  top: 27.5%;
  left: 11%;
  width: 42%;
  height: 21.5%; }
.room8_1 {
  top: 53%;
  left: 6%;
  width: 30%;
  height: 13%;
  border-bottom: none;
  border-right: none; }
.room8_2 {
  top: 53%;
  left: 36%;
  width: 17%;
  height: 13%;
  border-left: none; }
.room8_3 {
  top: 65.7%;
  left: 6%;
  width: 30%;
  height: 9%;
  border-top: none; }
.room9 {
  top: 11%;
  left: 54%;
  width: 40%;
  height: 28.5%; }
.room10 {
  top: 40%;
  left: 66%;
  width: 28%;
  height: 26%; }
.room10_1 {
  top: 40%;
  left: 66%;
  width: 6.6%;
  height: 22.5%;
  border-right: none; }
.room10_2 {
  top: 40%;
  left: 72%;
  width: 22%;
  height: 22.5%;
  border-left: none;
  border-bottom: none; }
.room10_3 {
  top: 62.5%;
  left: 72%;
  width: 22%;
  height: 4%;
  border-top: none; }
.room11 {
  top: 67%;
  left: 72%;
  width: 22%;
  height: 21.3%; }
.room12_1 {
  top: 40%;
  left: 54%;
  width: 10.5%;
  height: 23%;
  border-bottom: none; }
.room12_2 {
  top: 66.3%;
  left: 37%;
  width: 17.5%;
  height: 22%;
  border-right: none; }
.room12_3 {
  top: 66.3%;
  left: 54%;
  width: 10.5%;
  height: 22%;
  border: none;
  border-bottom: 2px solid white; }
.room12_4 {
  top: 63%;
  left: 64%;
  width: 7%;
  height: 25.3%;
  border-left: none; }
.room12_5 {
  top: 63%;
  left: 54%;
  width: 10.5%;
  height: 3.6%;
  border: none;
  border-left: 2px solid white; }

.area-container {
  position: absolute; }

.area-container:hover {
  cursor: pointer; }

.modal-card-foot {
  display: flex; }

.modal-button-container {
 flex: 1;
 text-align: center; }

@media screen and (max-width: 700px) {
  .map {
    object-fit: contain;
    height: auto;
    width: 100%; }
  .area-container {
    width: 100%;
    position: absolute; }
  svg.area-container {
    display: block; } }


.floorplan-trap-icon {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  img {
    max-width: 1.5em;
    max-height: 1.5em; }
  p {
    position: absolute;
    bottom: 10%;
    left: 100%;
    color: white;
    text-shadow: 2px 2px 2px #000;
    font-size: 0.8em;
    text-transform: uppercase; } }

.started-label {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  color: #b71c3d;
  width: 100%;
  text-align: center; }


.banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  z-index: 1000;
  color: white; }

.floor-picker {
  position: absolute;
  align-items: center;
  display: flex !important;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8vh;
  display: flex;
  z-index: 1000;
  &.disabled {
    display: none; } }

.floor-choice {
  flex: 1;
  text-align: center;
  font-size: 3rem;
  background: transparent;
  border-color: transparent;
  color: white;
  &:hover {
    color: white;
    font-weight: bold; } }

.hi-player {
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 2px; }

.banner-instructions {
  font-size: 2.5rem;
  line-height: 3rem; }

.realtime-button {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  img {
    height: 1.6rem;
    margin-right: 3px; }
  &.disabled {
    opacity: 0.3;
    cursor: default;
    &:hover {
      font-weight: normal; } } }

.realtime-picker-container.house {
  position: fixed;
  background: linear-gradient(rgba(0,0,0,0.45) 0%, rgba(0,0,0,1) 60%);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  transition: bottom 0.35s; }

.realtime-picker.house {
  align-items: center;
  display: flex; }

.realtime-picker-header.house {
  text-align: center;
  white-space: nowrap;
  margin-top: 0.5rem;
  color: white;
  letter-spacing: 1px;
  font-weight: bold; }

.realtime-trap-button.house {
  // width: 13vh
  width: 9vh; }

.realtime-trap-button-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2em;
  margin: 0.2em;
  p {
    font-size: 1.5em;
    color: whitesmoke; }
  &:hover {
    cursor: pointer; } }

.mana-bar-container {
  margin-top: -0.625em;
  margin-bottom: -0.875em;
  position: relative;
  text-align: center; }
.mana-bar {
  width: 90%;
  &.active {
    position: absolute;
    overflow: hidden;
    left: 5%; } }
.mana-bar-active-wrapper {
  position: absolute;
  z-index: 10;
  left: 5%;
  height: 10vh;
  overflow: hidden; }
.mana-bar-active {
  position: absolute;
  left: 0;
  right: 0; }
.mana-level {
  position: absolute;
  z-index: 10; }

.player-location-icon {
  position: absolute;
  width: 2em;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10; }

.floor-label {
  position: absolute;
  top: 12vh;
  left: 1.5em;
  z-index: 1000;
  color: white;
  font-size: 1.375rem; }

.room-name {
  color: #D2232A;
  font-weight: bold; }
.player-location-label {
  white-space: nowrap; }

svg {
  min-height: 100%;
  height: {} }


