html {
  background-color: black; }

.splash {
  z-index: 0;
  position: absolute;
  width: 100%;
  // height: 100%
  background: url('../../images/splash.jpg') no-repeat center center fixed;
  background-size: auto 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 80vw;
    max-width: 370px; } }
.splash-header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 7vh; }

.margin-top {
  margin-top: 1.5em; }

.flash-message {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 20px;
  right: 20px;
  animation: fadeIn 100ms linear;
  font-size: 1.2em; }

#preload {
  display: none; }

.code-input {
  border-radius: 7px;
  background: white;
  border: none;
  color: #333;
  text-align: center;
  width: 3rem;
  padding-top: 7px;
  padding-bottom: 8px;
  font-size: 1.625rem;
  outline: none; }

.rfinput {
  border-radius: 7px;
  background: rgba(0,0,0,0.6);
  border: 2px white solid;
  color: white;
  text-align: center;
  width: 50vw;
  max-width: 250px;
  padding-top: 7px;
  padding-bottom: 8px;
  font-size: 1.625rem;
  outline: none;
  &::placeholder {
    color: white;
    opacity: 0.6; } }

.rfbutton {
  border-radius: 7px;
  background: white;
  border: none;
  font-size: 1.625rem;
  width: 50vw;
  max-width: 250px;
  padding-top: 7px;
  padding-bottom: 8px;
  font-weight: bold;
  outline: none;
  cursor: pointer; }

.end-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.splash-container {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.splash-title {
  position: absolute;
  width: 75vw;
  max-width: 320px;
  top: 21%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

.social-logo {
  max-width: 3.75rem; }
