.black-bg {
  background-color: black; }
.blue-bg {
  background-color: #091523; }
.button {
  border: 1.5px solid #888 !important; }

.mt-2 {
  margin-top: 0.5rem; }
.mt-4 {
  margin-top: 1rem; }
.mt-6 {
  margin-top: 1.5rem; }
.mt-8 {
  margin-top: 2rem; }

.mb-1 {
  margin-bottom: 0.25rem; }
.mb-2 {
  margin-bottom: 0.5rem; }
.mb-4 {
  margin-bottom: 1rem; }
.mb-6 {
  margin-bottom: 1.5rem; }
.mb-8 {
  margin-bottom: 2rem; }
.mb-10 {
  margin-bottom: 2.5rem; }
.mb-12 {
  margin-bottom: 3rem; }

.mr-2 {
  margin-right: 0.5rem; }
.mr-4 {
  margin-right: 1rem; }
.mr-6 {
  margin-right: 1.5rem; }
.mr-8 {
  margin-right: 2rem; }

.flex {
  display: flex; }
.flex-row {
  flex-direction: row; }
.flex-col {
  flex-direction: column; }
.items-center {
  align-items: center; }
.justify-center {
  justify-content: center; }

.text-center {
  text-align: center; }
.bold {
  font-weight: bold; }
.pointer {
  cursor: pointer; }

.w-1 {
  width: 0.25rem; }
.w-2 {
  width: 0.5rem; }
.w-3 {
  width: 0.75rem; }
.w-4 {
  width: 1rem; }
.w-5 {
  width: 1.25rem; }
.w-6 {
  width: 1.5rem; }
.w-8 {
  width: 2rem; }
.w-10 {
  width: 2.5rem; }
.w-12 {
  width: 3rem; }
.w-14 {
  width: 3.5rem; }
.w-16 {
  width: 4rem; }

.text-xs {
  font-size: .75rem; }
.text-sm {
  font-size: .875rem; }
.text-base {
  font-size: 1rem; }
.text-lg {
  font-size: 1.125rem; }
.text-xl {
  font-size: 1.25rem; }
.text-2xl {
  font-size: 1.5rem; }
.text-3xl {
  font-size: 1.875rem; }
.text-4xl {
  font-size: 2.25rem; }
.text-5xl {
  font-size: 3rem; }
.text-6xl {
  font-size: 4rem; }

.text-white {
  color: white; }
